import React, { useEffect, Fragment } from "react"
import styled from "styled-components";
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from "../../general/faqsList"
import InnerPageHeader from "./components/innerPageHeader"
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards"
import MoreForYouComponent from "../../general/more-for-you";

import EntryIllustration from "../../../../../assets/img/business/virtualPOS-hero-illustration.svg"
import PosNairaIcon from "../../../../../assets/img/pos-naira.inline.svg"
import PosSendIcon from "../../../../../assets/img/pos-send.inline.svg"
import PosSettleIcon from "../../../../../assets/img/pos-settle.inline.svg"

import trackPaymentImage from "../../../../../assets/img/business/virtual_pos/trackPayment-illustration.svg"
import confirmTransactionImage from "../../../../../assets/img/business/virtual_pos/confirmTransaction-illustration.svg"
import streamlineImage from "../../../../../assets/img/business/virtual_pos/streamline-illustration.svg"

import { Img } from "react-image"
import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import SoftPOSIcon from '.././../../../../assets/img/more-for-you/kuda-softPos-icon.inline.svg'

const entryContent = {
  title: (
    <span>
      Accept and confirm payments everywhere with Kuda Business{" "}
      <span className="color-secondary">Virtual POS</span>!
    </span>
  ),
  subtitle:
    "Virtual POS makes it easy for your salespeople to confirm transfers instantly, saving your business and your customers time.",
  name: "Download Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <PosNairaIcon />,
    text: "Accept and track payments easily.",
  },
  {
    icon: <PosSendIcon />,
    text: "Confirm bank transfers instantly.",
  },
  {
    icon: <PosSettleIcon />,
    text: "Simplify your settlement process.",
  },
]

const trackPayment = {
  title: "Accept and track payments across locations easily.",
  subtitle:
    "Virtual POS simplifies payments for your customers with quick transfers, sends you an email notification for each transfer, and shows you all payments on a smart dashboard.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Get Kuda Business",
  illustration: <Img src={trackPaymentImage} className="kuda-business-image" />,
}

const confirmTransaction = {
  title: "Confirm all payments instantly.",
  subtitle:
    "Any time a customer makes a transfer, Virtual POS will send your salesperson a notification on their phone immediately to speed up payment confirmation.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (
    <Img src={confirmTransactionImage} className="kuda-business-image" />
  ),
}

const streamlinePayments = {
  title: "Streamline your settlement process.",
  subtitle:
    "Transfers made to all your Virtual POS terminals are settled in your Kuda Business account immediately, so you won’t need to do the extra work of reconciling your account.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Get Kuda Business",
  illustration: <Img src={streamlineImage} className="kuda-business-image" />,
}

const questions = [
  {
    heading: "What’s a Virtual POS?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16 mb-3">
          A Virtual POS is a unique account number that you can assign to your
          online store or business locations to accept payments.
        </span>
        <span className="faq-description f-16 mb-3">
          When a customer sends money to that account number, your salesperson
          will get a notification so that they can confirm the transfer
          immediately.
        </span>
        <span className="faq-description f-16">
          A virtual POS saves you and your customers time by making transfers
          quick, speeding up payment confirmation, and settling payments into
          your account instantly.
        </span>
      </span>
    ),
    index: 1,
  },
  {
    heading: "What’s the meaning of POS?",

    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">POS means point-of-sale.</span>
      </span>
    ),

    index: 2,
  },
  {
    heading: "How do I create a Kuda Business Virtual POS?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          To create a Kuda Business Virtual POS, follow the steps below:
        </span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3 line-height-2">
              1. Sign in to Kuda Business (or sign up){" "}
              <a
                href="https://kudabusiness.onelink.me/epwz/jceed731/"
                target="_blank"
                rel="noopener noreferrer"
                className="color-black link-decoration cursor-pointer ml-1"
              >
                here
              </a>
              .{" "}
            </div>
            <div className="mb-3 line-height-2">
              2. If you haven’t, upgrade your Kuda Business account with your
              CAC documents.
            </div>
            <div className="mb-3 line-height-2">
              3. Click POS, then click Get POS.
            </div>
            <div className="mb-3 line-height-2">
              4. Click Virtual POS, then click Continue.
            </div>
            <div className="mb-3 line-height-2">
              5. Fill in the required details, then click Continue.
            </div>
            <div className="mb-3 line-height-2">
              6. Download a poster that shows your Virtual POS payment details.
            </div>
          </div>
        </span>
      </div>
    ),
    index: 3,
  },
  {
    heading: "How much does Virtual POS cost?",

    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Kuda Business Virtual POS terminals are free to create.
        </span>
      </span>
    ),

    index: 4,
  },
  {
    heading: "Is Virtual POS secure for transactions?",

    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Yes, it’s secure. Virtual POS uses the highest security standards to
          make transactions secure.
        </span>
      </span>
    ),

    index: 5,
  },
  {
    heading: "What features does Virtual POS have?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Virtual POS features include:
        </span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3 line-height-2">
              1. A transaction dashboard on your Kuda Business app to see
              payments as they are made.
            </div>
            <div className="mb-3 line-height-2">
              2. Instant transaction notifications.
            </div>
            <div className="mb-3 line-height-2">3. Instant settlement.</div>
            <div className="mb-3 line-height-2">
              4. The option to create multiple Virtual POS terminals with unique
              account numbers.
            </div>
          </div>
        </span>
      </div>
    ),
    index: 6,
  },
  {
    heading: "What kinds of businesses can use Virtual POS?",

    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">Merchants, POS businesses (agent banking), freelancers and businesses including shops, hotels, supermarkets, bars and restaurants can use Virtual POS.</span>
      </span>
    ),

    index: 7,
  },
]

const moreForYou = [
  {
    icon: <SoftPOSIcon />,
    title: "softPOS",
    subText: `Turn your smartphone into a POS and give your customers more ways to pay you.`,
    linkTo: `/en-ng/business/softpos-payments/`
  },

  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },
]

const BusinessVirtualPOS = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <EntryContentWrapper>
        <InnerPageHeader
          title={entryContent.title}
          subtitle={entryContent.subtitle}
          buttonName={entryContent.name}
          buttonUrl={entryContent.url}
          entryIllustration={EntryIllustration}
        />
      </EntryContentWrapper>
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={trackPayment.title}
        subtitle={trackPayment.subtitle}
        illustration={trackPayment.illustration}
        name={trackPayment.name}
        url={trackPayment.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={confirmTransaction.title}
        subtitle={confirmTransaction.subtitle}
        illustration={confirmTransaction.illustration}
        name={confirmTransaction.name}
        url={confirmTransaction.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={streamlinePayments.title}
        subtitle={streamlinePayments.subtitle}
        illustration={streamlinePayments.illustration}
        name={streamlinePayments.name}
        url={streamlinePayments.url}
        isExternal={true}
      />
      <FAQList title={"Virtual POS FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessVirtualPOS;

const EntryContentWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    .business-header-title {
      line-height: 60.24px !important;
      max-width: 1200px !important;
    }
  }
`
